/**
 * Container management for Wood Tracking
 * Created by andrew.stalker on 24/10/2018.
 */

/* Container Function Globals */
var scanningContainer = {
		containerRef: "",
		storageIndex: -1,
		casks: [],
		containerLines: []
	};

/* globals requestTimeout, getRequestPath, renewServerSession, getScannerId, getScannerCompany, getScannerLocationCode, showAjaxLoading, hideAjaxLoading,
searchListForItem, sortByProperty, findObjectInArray, searchArrayForObject, showAlert, createBarcodeObject, saveContainerReceiptTransaction,
willDownloadExceedAllowance, getObjectSize */

// The condition codes are only used by containers so the couple of lines we need are here
/**
 * @description Save the condition codes to local storage
 * @param conditionCodes - The array of condition codes to be saved
 */
function setCaskConditionCodes(conditionCodes) {
	"use strict";
	localStorage.setObject("data/conditionCodes",conditionCodes);
}

/**
 * @description Get the condition codes saved on the scanner
 * @returns {*[]}
 */
function getCaskConditionCodes() {
	"use strict";
	return localStorage.getObject("data/conditionCodes");
}


/**
 * @description Checks to see if containers are in use for wood management.
 * @returns {boolean} True if they are
 */
function areContainersInUse() {
	"use strict";
	var value = localStorage.getVariable("settings/downloadContainers").toUpperCase();
	return (value === "TRUE" || value === "YES");
}

/**
 * @description Gets a list of available containers from the server
 * @param {Boolean} filterDownloaded - True if response should filter out those already on the scanner
 * @param callback - A function to run when the request responds. Returns data.
 */
function getContainerList(filterDownloaded, callback) {
	"use strict";
	var scanner = getScannerId(),
		company = getScannerCompany(),
		locationCode = getScannerLocationCode();

	$.ajax({
		url: getRequestPath("container/list?company=" + company + "&location=" + locationCode + "&scanner=" + scanner),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {
			withCredentials: true
		},
		success: function (listResponseData) {
			if(filterDownloaded) {
				var alreadyDownloadedContainers = getContainersFromStorage(),
					  containersAvailable = listResponseData.containers;
				for(var i = containersAvailable.length-1; i>=0; i--) {
					if (searchListForItem(alreadyDownloadedContainers,"containerRef",containersAvailable[i].containerRef) !== null) {
						containersAvailable.splice(i,1);
					}
				}
				callback(containersAvailable);
			} else {
				callback(listResponseData.containers);
			}
		},
		error: function (xhr) {
			if (xhr.status === 401 && canReattemptAfterLogin()) {
				renewServerSession(function (valid) {
					if (valid) {
						getContainerList(filterDownloaded, callback);
					} else {
						callback([]);
					}
				});
			} else {
				callback([]);
			}
		}
	});
}

/**
 * @description downloads a specific container from the server, and merges with the already downloaded data
 * @param containerRef
 * @param callback - Function to run when complete.
 * Success returns a (true, statusCode, containersMerged[], containersDownloaded[]). Error returns (false,xhr.status)
 */
function downloadContainer(containerRef, callback) {
	"use strict";

	// set variables
	var scanner = getScannerId(),
		company = getScannerCompany(),
		locationCode = getScannerLocationCode();
	// ajax get single
	showAjaxLoading();

	$.ajax({
		url: getRequestPath("container?containerRefs="+containerRef+"&company=" + company + "&location=" + locationCode + "&scanner=" + scanner),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {
			withCredentials: true
		},
		success: function (containerData) {
			hideAjaxLoading();
			if (containerData.containers) {
				// Check we have at least one record
				if (containerData.containers[0]) {
					mergeWithContainersInStorage(containerData.containers, function(resultingContainers, storageSuccessful) {
						// Merge completed by this point
						//check for storage successful
						if (storageSuccessful) {
							callback(true, 200, resultingContainers, containerData.containers);
						} else {
							callback(true, 800, resultingContainers,containerData.containers);
						}
					});
				} else {
					callback(true, 404);
				}
			} else {
				callback (false, 404);
			}
		},
		error: function (xhr) {
			hideAjaxLoading();
			if (xhr.status === 401 && canReattemptAfterLogin()) {
				renewServerSession(function (valid) {
					if (valid) {
						downloadContainer(containerRef, callback);
					} else {
						callback(false, 401);
					}
				});
			} else {
				callback(false, xhr.status);
			}
		}
	});
}

/**
 * @description Updates any containers already downloaded to the scanner.
 * @param callback - The function to run once completed. callback(remainingContainersOnScanner).
 */
function updateDownloadedContainers(callback) {
	"use strict";
	// Create the list of containers to download
	var containers = getContainersFromStorage(),
		  containerList = "";
	for (var i=0; i<containers.length; i++) {
		containerList += "," + containers[i].containerRef;
	}
	containerList = containerList.substr(1);
	downloadContainer(containerList, function(success, responseCode, mergedContainers, downloadedContainers) {
		if (success) {
			// The update was successful but we may not have containers..
			if (responseCode === 200 || responseCode === 800) {
				// Response code was 200, so we received at least one
				// Response code was 800, so we cannot exceed quota.
				// We will remove any containers from the scanner that were not downloaded
				removeOldContainersFromStorage(mergedContainers,downloadedContainers, function(remainingContainers) {
					callback(remainingContainers, responseCode);
				});
			} else {
				// Response code would be 404, therefore no containers are available.
				// We now clear out the downloaded containers as they were not received in the update.
				setContainersInStorage([]);
				callback([], responseCode);
			}
		} else {
			if (responseCode === 404) {
				// If the server returned a 404
				setContainersInStorage([]);
				callback([], responseCode);
			} else {
				showAlert("Could not update Containers ("+responseCode+").", {callback: function() {
					callback(null, responseCode);
				}});
			}
		}
	});
}

/**
 * @description Merges downloaded containers with those already stored on the scanner
 * @param {object} newContainers The newly downloaded containers
 * @param {*} callback A function to call once merge has completed.
 */
function mergeWithContainersInStorage(newContainers, callback) {
	"use strict";
	var currentStore = getContainersFromStorage(),
		startingStoreSize = getObjectSize(currentStore);
	for (var i=0; i<newContainers.length; i++) {
		var oldCopyIndex = searchListForItem(currentStore,"containerRef",newContainers[i].containerRef);
		if (oldCopyIndex !== null) {
			currentStore[oldCopyIndex] = newContainers[i]; // Replace old copy with the new one.
		} else {
			currentStore.push(newContainers[i]); // Add on to the end.
		}
	}
	// resort the containers by containerRef.
	currentStore.sort(sortByProperty("containerRef"));

	if (willDownloadExceedAllowance(currentStore, startingStoreSize)) {
		showAlert("Cannot download container data. Data would exceed Quota.", {type: "error", callback: function() {
			callback(getContainersFromStorage(), false);
		}
	});
	} else {
	// Save back to LS
	setContainersInStorage(currentStore);
	callback(currentStore, true);
  }
}

/**
 * @description Get containers stored on the scanner
 */
function getContainersFromStorage() {
	"use strict";
	return localStorage.getObject("data/containers");
}

/**
 * @description Get a specific container from the scanner
 * @param containerRef - The containerRef of the container required
 * @returns {*} Returns the container if found on the scanner.
 */
function getContainerFromStorage(containerRef) {
	"use strict";
	var allContainers = getContainersFromStorage();
  return findObjectInArray(allContainers,"containerRef", containerRef);
}

/**
 * @description Saves the containers back to storage on the scanner.
 * @param containers - The containers array to save back to the scanner.
 */
function setContainersInStorage(containers) {
	"use strict";
	localStorage.setObject("data/containers", containers);
}

/**
 * @description Removes a specified container from the scanner
 * @param {string} containerRef - The containerRef of the container to be removed
 * @param callback - The function to run once the container has been removed, passing the remaining containers back
 * I.E. callback(remainingContainers)
 */
function removeContainerFromStorage(containerRef, callback) {
  "use strict";
	var containers = getContainersFromStorage();
	var containerToRemove = searchArrayForObject(containers,"containerRef",containerRef);
	if (containerToRemove >= 0) {
		containers.splice(containerToRemove,1);
	}
	setContainersInStorage(containers);
	callback(containers);
}

/**
 * @description Compares downloaded containers with those on the scanner. Removes any not downloaded.
 * This should only be used when updating in bulk and never on a single download.
 * @param scannerContainers - The containers already on the scanner if preloaded by the calling function
 * @param downloadedContainers - The containers downloaded, from the list end point or an update
 * @param callback - The function to run when updated. The remaining containers are passed I.E. callback(remainingContainers).
 */
function removeOldContainersFromStorage(scannerContainers, downloadedContainers, callback) {
	"use strict";
	var containersToKeep = (scannerContainers)? scannerContainers : getContainersFromStorage();
	for (var i=containersToKeep.length-1; i>=0;i--) {
		var downloadedIndex = searchArrayForObject(downloadedContainers,"containerRef",containersToKeep[i].containerRef);
		// If we didn't find the container with matching containerRef in the downloaded list then remove it
		if (downloadedIndex === -1) {
			containersToKeep.splice(i,1);
		}
	}
	setContainersInStorage(containersToKeep);
	callback(containersToKeep);
}

/**
 * @description Sets up the global variable for scanning containers
 * @param {String} containerRef - The containerRef to scan against
 * @param callback - The function run once setup is completed
 */
function setScanningContainer(containerRef, callback) {
	"use strict";
	var allContainers = getContainersFromStorage(),
		  containerIndex = searchArrayForObject( allContainers,"containerRef", containerRef),
	    container = (containerIndex >= 0)? allContainers[containerIndex] : null;
	if (container === null) {
		callback(false); // An error occurred
	} else {
		// Set the container details in the scanning object
		scanningContainer.containerRef = containerRef;
		scanningContainer.storageIndex = containerIndex;
		scanningContainer.casks = container.casks || [];
		scanningContainer.containerLines = container.lines || [];
		callback(true);
	}
}

/**
 * @description Counts the number of casks in the scanningContainer that have been scanned for the containerLine
 * @param {String} woodCode The wood code of the container line.
 * @param {Number} caskType The cask type of the container line.
 * @returns {Number} The quantity scanned against the line.
 */
function countCasksScannedForContainerLine(woodCode, caskType) {
	"use strict";
	var casks = scanningContainer.casks.filter(function (el) {
		return (el.woodCode === woodCode) && (el.caskType === caskType);
	});
	return casks.length;
}

function findContainerLine(woodCode, caskType) {
	"use strict";
	var containerLines = scanningContainer.containerLines.filter(function (el) {
		return (el.woodCode === woodCode) && (el.caskType === caskType);
	});
	if (containerLines.length > 0) {
		return containerLines[0];
	} else {
		return null;
	}
}

function findCaskScannedInContainer(caskId) {
	"use strict";
	return searchArrayForObject(scanningContainer.casks,"caskId",caskId);
}

/**
 * @description Custom processing for scanning a barcode in container receipt program
 * @param {String} scannedCode The scanned barcode
 * @param {Object} containerLine
 * @param {String} conditionCode
 * @param displayCallback Function run once processing is done successfully so that info can be displayed if needed.
 */
function processContainerScanEvent(scannedCode, containerLine, conditionCode, displayCallback) {
	"use strict";
	//Custom code is needed as we don't do anything normal with a barcode scanned here
	var barcodeObject = createBarcodeObject(scannedCode,"scanInput"),
		validCaskId = false;
	// barcode must be a cask id, cannot tell if it is when its double scanned or otherwise
	switch(barcodeObject.type) {
		case "CUSTOMID":
		case "WTL":
		case "WTLS":
		case "WTLL":
			// These are all cask label types. Valid.
			validCaskId = true;
			break;
		default:
			// An invalid barcode gets alerted before in createBarcodeObject. Die silent here
			break;
	}
	// could be double scanned / repeat scanned to change the properties of the cask
	// no allocation validation
	// custom display fields.
	if (!validCaskId) return;

	// check the callbacks, i thing some of those must be the issue.
		var scannedCaskPosition = findCaskScannedInContainer(barcodeObject.caskId);
		if (scannedCaskPosition >= 0) {
			//Has already been scanned
			var previousCaskState = scanningContainer.casks[scannedCaskPosition];
			if (previousCaskState.woodCode === containerLine.woodCode && previousCaskState.caskType === containerLine.caskType) {
				// Previous detail is the same as now. Check to see if the condition has changed
				if (previousCaskState.conditionCode === conditionCode) {
					showAlert("This cask has already been scanned.", {focus: "scanInput", callback: function() {}});
				} else {
					// The condition has changed. Ask
					showAlert("The condition of this cask has already been scanned as " + previousCaskState.conditionCode +
						". Do you want to change the condition to " + conditionCode + "?", {canCancel: true, callback:function() {
						// User has pressed yes on the alert dialog, update the cask.
						saveContainerCask(scannedCaskPosition, barcodeObject, containerLine.woodCode, containerLine.caskType, conditionCode, function(newContainerLineCaskCount) {
							displayCallback(barcodeObject.displayCode, containerLine.woodType, containerLine.caskDescription, newContainerLineCaskCount);
						});
					}});
				}
			} else {
				// Check the newly scanned container line is not full already.
				if (countCasksScannedForContainerLine(containerLine.woodCode, containerLine.caskType) >= containerLine.quantity) {
					// The container line has already had enough scans for it, so either the user has scanned something wrong,
					// I.E. a cask has been scanned wrong or the user has scanned by mistake.
					showAlert("The container line is full, all " + containerLine.quantity +" casks have been scanned.");
				} else {
					//Find container line that matches.
					var previousContainerLine = findContainerLine(previousCaskState.woodCode, previousCaskState.caskType);
					if (previousCaskState) {
						// The cask has already bee scanned, and we want to see if details are to be changed.
						// This will usually be for type and wood code change, but also the condition is noted, in case the user forgets to change the drop down.
						showAlert("This cask has already been scanned as " + previousContainerLine.woodType +
							" - " + previousContainerLine.caskDescription + ". Condition: "+previousCaskState.conditionCode+". Update this cask to " +
							containerLine.woodType + " - " + containerLine.caskDescription + ". Condition: "+conditionCode+ "?",
							{focus:"scanInput",type: "info", canCancel: true, callback: function() {
								// User has allowed the rescan
								saveContainerCask(scannedCaskPosition, barcodeObject, containerLine.woodCode, containerLine.caskType, conditionCode, function(newContainerLineCaskCount) {
									displayCallback(barcodeObject.displayCode, containerLine.woodType, containerLine.caskDescription, newContainerLineCaskCount);
								});
							}});
					}
				}
			}
		} else {
			// Never been scanned before - check the container line is not full.
			if (countCasksScannedForContainerLine(containerLine.woodCode, containerLine.caskType) >= containerLine.quantity) {
				// The container line has already had enough scans for it, so either the user has scanned something wrong,
				// I.E. a cask has been scanned wrong or the user has scanned by mistake.
				showAlert("The container line is full, all " + containerLine.quantity +" casks have been scanned.");
			} else {
				saveContainerCask(-1, barcodeObject, containerLine.woodCode, containerLine.caskType, conditionCode, function(newContainerLineCaskCount) {
					displayCallback(barcodeObject.displayCode, containerLine.woodType, containerLine.caskDescription, newContainerLineCaskCount);
				});
			}
		}
}

/**
 * @description Saves the container cask to LS, and calls to create transaction.
 * @param position
 * @param barcodeObject
 * @param woodCode
 * @param caskType
 * @param conditionCode
 * @param callback
 */
function saveContainerCask(position, barcodeObject, woodCode, caskType, conditionCode, callback) {
  "use strict";
	// Save a container scan transaction
	saveContainerReceiptTransaction(barcodeObject, woodCode, caskType, conditionCode, function(success) {
		if (success) {
			// Save the cask to the casks array of the scanningContainer
			// if position >= 0 then we need to splice it in as a replacement, otherwise we push.
			if (position >= 0) {
				scanningContainer.casks[position] = {caskId:barcodeObject.caskId,woodCode:woodCode,caskType:caskType,conditionCode:conditionCode};
			} else {
				scanningContainer.casks.push({caskId:barcodeObject.caskId,woodCode:woodCode,caskType:caskType,conditionCode:conditionCode});
			}

			// Save the scanningContainer casks array back to the container LS
			var storageContainers = getContainersFromStorage();
			storageContainers[scanningContainer.storageIndex].casks = scanningContainer.casks;
			setContainersInStorage(storageContainers);

			//We're done, so callback so that the screen can update
			callback(countCasksScannedForContainerLine(woodCode, caskType));
		}
	});
	// No else declared as we do not fire any display callback.
}

/**
 *
 * @typedef {object} listResponseData Successful response body of list request
 * @property {object} containers An array of container records
 *
 * @typedef {object} containerData Successful response of single
 * @property {object} containers An array of container records
 *
 *
 * @typedef {object} container An Allocation Object
 * @property {String} containerRef Container Reference number
 * @property {String} poNumber Purchase Order number if applicable
 * @property {object} lines An array of container Lines
 * @property {object} casks An array of casks already scanned
 *
 * @typedef {object} containerLine
 * @property {Number} caskType
 * @property {String} caskDescription
 * @property {String} woodCode
 * @property {String} woodType
 * @property {Number} quantity
 **/
